.container{
    display: flex;
    flex-direction: column; 
}
.container > * {
    padding: 8px 20px 8px;
  }
.label{
    font-weight: 800;
    text-align: center;
    padding-top: 25px;
}
.thumbnail__container {
    display: flex;
    justify-content: center;
    padding-bottom: 28px;
  }
  
.thumbnail__image {
    width: 65px; 
    height: auto; 
    margin: 0 5px;
}
.thumbnail__image__selected {
    border: 2px solid #7db7f4;
    border-radius: 6px;
}
.button__container{
    padding-top: 25px;
}
.button{
    width: 90px;
    height: 48px;
    padding: 12px, 24px, 12px, 24px;
    border-radius: 4px
}
.image__container {
    display: flex;
    flex-direction: row;
    position: relative;
}
.main__image {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 6px;
  }
  .arrow {
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    width: 12px;
    margin: 10px;
    height: auto;
    cursor: pointer;
}
.arrow:first-child {
    left: 0;
}
.arrow:last-child {
    right: 0;
}