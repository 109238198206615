.drawer__container {
  display: none;
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  width: 401px;
  padding: 2em;
  z-index: 501;
  background: linear-gradient(134.56deg, #0072ac 3.73%, #005682 100%);
  color: #ffffff;
  overflow: auto;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

@keyframes drawer-open {
  from {
    left: -401px;
  }
  to {
    left: 0px;
  }
}

@keyframes drawer-closed {
  from {
    left: 0px;
  }
  to {
    left: -401px;
  }
}

@media (orientation: portrait) {
  .drawer__container {
    top: 60px;
    width: 90%;
    max-width: 355px;
  }

  @keyframes drawer-open {
    from {
      left: -90%;
    }
    to {
      left: 0px;
    }
  }

  @keyframes drawer-closed {
    from {
      left: 0px;
    }
    to {
      left: -90%;
    }
  }
}

@media screen and (max-width: 1194px) {
  .drawer__container {
    top: 60px;
  }
}

.drawer__container--open {
  display: flex;
  flex-direction: column;
  animation: drawer-open 250ms forwards;
}

.drawer__container--closed {
  display: flex;
  flex-direction: column;
  animation: drawer-closed 250ms forwards;
}

.heading__container {
  padding-bottom: 14%;
  border-bottom: 1px solid #ffffff;
  text-align: left;
}

.heading__text {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.1px;
  margin-bottom: 0.5em;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.sub-heading__text {
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
}

.drawer {
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  flex: 1;
}

.content__container {
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  flex: 1;
}

.close-button__container {
  position: sticky;
  bottom: 0px;
  text-align: left;
}

.close-button {
  border-radius: 4px;
  width: 112px;
  height: 48px;
  border: 1px solid #ffffff;
  text-align: center;
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  background: linear-gradient(134.56deg, #0072ac 3.73%, #005682 100%);
}

.close-button__text {
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  color: #ffffff;
}

.drawer__overlay {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background: #494949;
  opacity: 0.85;
}

@keyframes overlay-open {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 0.9;
    visibility: visible;
  }
}

@keyframes overlay-closed {
  from {
    opacity: 0.9;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.drawer__overlay--visible {
  z-index: 500;
  animation: overlay-open 250ms forwards;
}

.drawer__overlay--hidden {
  z-index: 0;
  animation: overlay-closed 250ms forwards;
}
