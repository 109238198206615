.container {
  width: 100%;
  max-width: 992px;
  padding: 0 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #004165;
}

.select {
  width: 250px !important;
}

/* position of arrow for select element */
.select + div {
  right: calc(100% - 250px);
}

.continue {
  margin-left: auto;
}
.visually__hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  white-space: nowrap;
}