.container {
  flex-direction: column;
  padding: 0 1.5em;
  max-width: 992px;
  margin: auto;
  max-height: 36.25em;
  min-height: 30em;
  transition: max-width 0.2s ease 0s;
  overflow: auto;
}

.form__container {
  width: calc(100% - 3.5em);
}

@media screen and (min-width: 768px) {
  .container {
    max-height: calc(100vh - 240px);
  }
}

@media screen and (min-width: 960px) {
  .form__container {
    width: 75%;
  }
}

.header {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.625em;
  left: 8.21%;
  right: -86.15%;
  top: 27.61%;
  bottom: 68.72%;
  color: #484848;
  margin-bottom: 10px;
}

.tip {
  left: 8.46%;
  right: 9.74%;
  top: 31.28%;
  bottom: 65.05%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  color: #484848;
}

.field {
  border-radius: 0.25em;
  font-size: 16px;
  height: 44px !important;
}

.field__large {
  width: 100%;
  border-radius: 0.25em;
  font-size: 16px;
  font-family: 'myriad-pro', Helvetica, Arial, sans-serif;
  padding: 0.75em;
  border: 1px solid #cdcdcd;
  color: #484848;
  height: 5.375em;
  resize: none;
  vertical-align: middle;
}

.field__large:focus {
  border-color: #0093d8;
  outline: 0;
}

.field__large::placeholder {
  color: #c6c6c6
}

.label {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px;
  color: #494949;
  letter-spacing: normal;
  transition: font-size 0.2s ease 0s, transform 0.2s ease 0s, color 0.2s ease 0s;
}

.button__bar__container > div {
  gap: 15px;
}

.btn__confirm {
  background-color: #008a02 !important;
}

.check__name {
  margin-top: -1.7em;
  position: relative;
  margin-bottom: -1em;
}
.visually__hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  white-space: nowrap;
}
.link__button {
  padding: 0px !important;
  text-decoration: underline;
}

.address__field div{
  white-space: pre-wrap;
}
.form__divider {
  border-top: 1px solid rgb(233, 229, 229) !important;
  margin: 30px 0 !important;
}
.input__DLCN {
  padding: 20px 0 0 0 !important;
}
.error__link__button {
  padding-left: 26px;
}
