* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased !important;
  font-family: 'myriad-pro', sans-serif !important;
  font-size: 16px !important;
  overflow: visible !important;
}
