.camera__container {
  height: calc(100vh - 60px);
}

.camera__container_preview {
  height: calc(100vh - 146.3px);
}

@media screen and (min-width: 1194px) {
  .camera__container {
    height: calc(100vh - 70px);
  }

  .camera__container_preview {
    height: calc(100vh - 156.3px);
  }
}
