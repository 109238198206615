.container {
  display: flex;
  margin: 10px auto auto auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 992px;
  
}

.header {
  font-weight: 600;
  line-height: 1.125;
  letter-spacing: normal;
  color: #004165;
  font-size: 34px;
  margin-top: 18px;
  margin-bottom: 18px;
  padding-left: 24px;
  width: 100%;
}

.drawer__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;
}

.drawer__content {
  margin: 14% 0;
}

.identity__header,
.photo__header {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  margin-top: -0.25em;
  margin-bottom: 0.25em;
}

.identityinstructions__container,
.photo__container {
  display: flex;
  gap: 10px;
}

.identitydocuments {
  display: flex;
  flex-direction: column;
  align-self: start;
}

.bullet {
  min-width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: 600;
  size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  color: #007dba;
  margin-left: 16px;
}

.documents {
  font-weight: 400;
  line-height: 24px;
}
