.spinner__overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background: #494949;
  opacity: 0.85;
  z-index: 600;
}

.spinner__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 30%;
  z-index: 601;
  color: #ffffff;
  width: 100%;
}

.spinner__title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 32px;
  margin: 0.5em;
  text-align: center;
}

.svg {
  animation: spin 1s linear infinite;
  transform-origin: center center 0px;
  width: 100px;
  height: 100px;
  margin: 0.5em;
}

.transform {
  transform-origin: center center 0px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner__description {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 24px;
  margin: 0.5em;
  text-align: center;
  white-space: pre-line;
}
