.root__container {
  background: linear-gradient(315deg, #394a58 0%, #7a99ac 100%);
  height: 100vh;
  overflow: auto;
}

.preview__mode {
  background: #ffffff;
}

.camera__container {
  color: #ffffff;
  padding: 2em;
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.selfie__container,
.document__heading__container {
  text-align: left;
  max-width: 992px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.3em;
  border-bottom: 1px solid #ffffff;
}

.selfie__title,
.document__heading {
  font-style: normal;
  font-weight: 600;
  font-size: 2.2em;
  line-height: 40px;
  letter-spacing: -0.1px;
  padding-bottom: 0.5em;
  height: 1.5em;
}

.document__heading__container {
  margin-bottom: 1.2em;
}

.document__heading {
  height: 2.3em;
}

.selfie__description,
.document-description__text {
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  display: flex;
  flex: 1;
  margin-top: 0.5em;
  height: 100px;
  flex-direction: column;
  width: 100%;
  align-self: center;
  align-items: flex-start;
}

.selfie__description {
  min-height: 23px;
}

.document-description__text {
  display: inline-block;
  width: auto;
}

@media screen and (min-width: 1194px) {
  .selfie__title, .document__heading {
    height: 3em;
  }
  .selfie__description {
    margin-top: 3.5em;
  }
}

.selfie__timer__container {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  margin-top: -3.3em;
}

.selfie__timer__container > div:first-child {
  align-self: flex-end;
  width: 40%;
}

.selfie__timer__count {
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  display: block;
  align-self: flex-start;
  width: 60%;
  text-align: right;
}

@keyframes timer-warning {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.selfie__timer__count > span:first-child {
  font-size: 5em;
  font-weight: 600;
  color: #df7a00;
  width: 50%;
  animation: timer-warning 1s linear infinite;
  text-align: right;
}

.selfie__timer__count > span:nth-child(2) {
  width: 50%;
}

.selfie__timer__count > span > span {
  width: 40px;
  display: inline-block;
}

.document-title__text {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 32px;
  letter-spacing: -0.1px;
  margin-bottom: 0.5em;
}

.preview__mode .document-title__text {
  color: #0072ac;
}
.preview__mode .document-description__text {
  color: #484848;
}
.preview__mode .selfie__container {
  border-bottom: 1px solid #004165;
}
.preview__mode .selfie__title {
  color: #004165;
}

.button__container {
  display: flex;
  flex: 1;
  align-items: center;
  margin: 2em 0 2em 0;
}

.button {
  border-radius: 4px;
  width: 99px;
  height: 48px;
  border: 1px solid #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 1em;
  line-height: 24px;
  cursor: pointer;
}

.button__text {
  font-weight: 600;
  font-size: 1em;
  line-height: 24px;
  color: #ffffff;
}

.capture-button {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: none;
  border: 3px solid #ffffff;
  -webkit-tap-highlight-color: transparent;
  background-clip: content-box;
  padding: 3px;
  background-color: #fff;
}

.capture-button__text {
  display: none;
}

.retake-button {
  background: none;
  margin: 0.5em;
  border: 1px solid #0072ac;
}

.retake-button__text {
  color: #0072ac;
}

.submit-button {
  background: #008a02;
  margin: 0.5em;
  border: none;
}

@media (orientation: landscape) and (min-width: 640px) {
  .button__container > div {
    width: 640px;
    display: flex;
    flex: 1;
    align-items: center;
  }
  .retake-button {
    margin-left: auto;
  }
}

.constraints-description__text {
  font-weight: 400;
  font-size: 0.8em;
  line-height: 24px;
}

.bottom__container {
  display: flex;
  flex: 1;
  margin: 2em 0 2em 0;
  max-width: 992px;
}

.bottom__container > .button {
  color: #ffffff;
  padding: 0 0.8em 0 0.8em;
  background: none;
  width: auto;
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.switch-camera-button {
  margin-left: 0.8em;
}

@keyframes padding-increases {
  0% {
    padding: 3px;
  }
  100% {
    padding: 6px;
  }
}

@media (any-pointer: fine) {
  .capture-button:hover, .capture-button:focus, .capture-button:active {
    padding: 6px;
    animation: padding-increases 0.1s linear;
  }
  .retake-button:hover {
    background: #dce9f0;
  }
  .switch-camera-button:hover,
  .back-navigation-button:hover {
    background: #7a99ac;
  }
  .submit-button:hover {
    background: #36a738;
  }
}

/* Video and Preview */

.video__container {
  margin-top: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.video {
  width: 640px;
  height: 480px;
  object-fit: contain;
  background: #000000;
  border-radius: 10px;
}

.video__document {
  max-width: 640px;
  max-height: 480px;
  width: 100% !important;
  height: auto !important;
}

.video__selfie {
  max-width: 480px;
  max-height: 640px;
  width: 100% !important;
  height: auto !important;
}

@media (orientation: landscape) {
  .video__selfie {
    max-width: 640px;
    max-height: 480px;
    width: 100% !important;
    height: auto !important;
  }
}

@keyframes focus-box-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

.video__focus-box {
  animation: focus-box-appear 1s linear;
  opacity: 0.8;
}

@keyframes preview-appear {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.capture-preview {
  margin-top: 15px;
  width: 100%;
  height: 100%;
  animation: preview-appear 1s linear;
  display: none;
  border-radius: 10px;
}

.capture-preview__document {
  max-width: 640px;
  max-height: 480px;
  display: block;
}

.capture-preview__selfie {
  max-width: 480px;
  max-height: 640px;
  display: block;
}

@media (orientation: landscape) {
  .capture-preview__selfie {
    max-width: 640px;
    max-height: 480px;
    display: block;
  }
}

.context-width__document {
  max-width: 640px;
}

.context-width__selfie {
  max-width: 992px;
}
