.main {
  display: flex;
  justify-content: center;
  height: calc(100vh - 60px);
}

.container {
  display: flex;
  flex-direction: column;
  margin: 32px 24px 0px 24px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;

  /* UX: Keep terms content container & buttons within view port.
  This is so that an outer scroll is not needed. 
  It hard to outer scroll on mobile because left and right margins are thin. */
  min-height: 480px;
  max-width: 992px;
  transition: max-width 0.2s ease 0s;
}

@media screen and (min-width: 960px) {
  /* Center the element on desktop displays */
  .main {
    align-items: center;
  }
  .container {
    max-height: 681px;
    margin: 0
  }
}

.header {
  font-size: 34px;
  line-height: 40px;
  font-weight: 600;
  color: #004165;
  padding-bottom: 1em;
  margin-bottom: 32px;
  border-bottom: 1px solid #004165;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2.6em;
  padding: 32px 22px 60px 22px;  
  overflow-y: scroll;
  color: #6E6E6E;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

.heading {
  color: #016699;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 0.6em;
}

.large__text {
  color: #494949;
  font-size: 20px;
  font-weight: 600;
}

.next__heading {
  font-size: 20px;
  font-weight: 600;
  color: #494949;
  margin-bottom: 0.6em;
}