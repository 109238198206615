.input {
  padding: 0 24px 0 12px;
  width: 100%;
  background-color: transparent;
  font-size: 16px;
  color: #494949;
  height: 44px;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease 0s, border-radius 0.2s ease 0s;
}

.input:hover,
.input:focus {
  border: 1px solid #0093d8;
  box-shadow: none;
}

.input::placeholder {
  color: #aeaeae;
}

.label {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px;
  color: #494949;
  letter-spacing: normal;
  transition: font-size 0.2s ease 0s, transform 0.2s ease 0s, color 0.2s ease 0s;
}

.fieldElements {
  margin-top: 0.5em;
}

.fieldClassName {
  padding: 0 0 1.5em 0;
}