.selfie_drawer__content {
  margin: 14% 0;
}

.tips__container {
  margin-top: 1.5em;
}

.tips__container .illustrations__container {
  display: flex;
}

.tips__header {
  font-weight: 600;
  font-size: 1.5em;
  margin-left: 0.5em;
  vertical-align: middle;
}

.tips__text {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.tips__text > :last-child {
  margin-top: 1.25em;
}

.illustrations__container {
  margin-top: 1em;
  margin-bottom: 4em;
}

.illustrations__container > :first-child {
  margin-right: 1em;
}

@media screen and (max-width: 376px) {
  .tips__container {
    margin-top: 0.25em;
  }

  .tips__text {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .illustrations__container {
    margin-top: 0.5em;
    margin-bottom: 2.2em;
  }
}

.camera__container {
  height: calc(100vh - 60px);
}

@media screen and (min-width: 1194px) {
  .camera__container {
    height: calc(100vh - 70px);
  }
}
