.content__container {
  padding: 48px;
  margin: 0px;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  flex: 1 0 auto;
}

@media screen and (min-width: 768px) {
  .content__container {
    margin: 48px auto;
    padding: 0;
  }
}
