.anz__header {
  position: relative;
  z-index: 501;
  width: 100%;
  top: 0px;
}

.anz__header__section {
  height: 55px;
  width: 100%;
  background-color: #004165;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 1194px) {
  .anz__header__section {
    height: 65px;
  }
}

.anz__logo__container {
  padding-left: 24px;
  padding-right: 24px;
}

.anz__logo__link {
  color: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.anz__logo {
  width: 103px;
  height: 42px;
  margin-top: 8px;
}

.icon__caption {
  color: #c6dfea;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
  margin-right: 12px;
  display: block;
}

.icon__help {
  margin-bottom: 6px;
  margin-left: 3px;
}
.icon__support {
  margin-bottom: 6px;
  margin-left: 10px;
}
.icon__exit {
  margin-bottom: 6px;
  margin-left: -5px;
}

.icons__container {
  z-index: 501;
  padding-top: 12px;
  margin-right: 20px;
  display: flex;
}
.icon__container {
  margin-left: 8px;
}

.blue__crowbar {
  height: 5px;
  left: 0%;
  right: 0%;
  top: 55px;
  background: #007dba;
}

.ekyc__text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #ffffff;
  padding-top: 25px;
  flex-grow: 1;
  margin-left: -17px;
}
