.dateInput__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dateInput {
  height: 44px !important;
}

.dateInput:hover,
.dateInput:focus {
  border: 1px solid #0093d8;
  box-shadow: none;
}

.dateInput--error {
  border-color: #c91b01 !important;
}

.label {
  font-size: 16px;
  font-weight: 400;
  color: #494949;
  letter-spacing: normal;
  transition: font-size 0.2s ease 0s, transform 0.2s ease 0s, color 0.2s ease 0s;
}

.label--error {
  color: #c91b01;
}

.error__text {
  padding: 0.608em 0;
  font-size: 0.875em;
  color: #c91b01;
}

.dateFields__container {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  align-items: baseline;
}