.landingform__container {
  width: 100%;
  max-width: 100%;
  transition: max-width 0.2s ease 0s;
  padding: 0px;
}

@media screen and (min-width: 768px) {
  .landingform__container {
    min-height: 330px;
    background-color: #ffffff;
    border: 1px solid #e2e4e6;
    border-radius: 3px;
    max-width: 560px;
    padding: 64px;
    margin-top: 0px;
    width: 418px;
    margin-right: 30px;
  }
}

@media screen and (min-width: 960px) {
  .landingform__container {
    width: 530px;
  }
}

@media screen and (min-width: 1164px) {
  .landingform__container {
    width: 553px;
    margin-right: 50px;
  }
}

.form__header {
  font-weight: 600;
  line-height: 40px;
  letter-spacing: normal;
  color: #004165;
  font-size: 34px;
  margin-bottom: 32px;
}

.button__container {
  margin-top: 40px;
  margin-bottom: 20px;
}

.button__svg {
  transform: translate3d(0px, 0.125em, 0px);
  margin: -0.125em 0px 0px;
  margin-right: 8px;
  margin-bottom: 4px;
}

.privacy {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #494949;
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .button__container {
    margin-bottom: 30px;
  }
  .privacy {
    margin-top: 40px;
  }
}

.tip {
  font-size: 14px;
  margin-top: -21px;
  color: #494949;
}

hr {
  box-sizing: content-box;
  height: 0px;
  overflow: visible;
  margin: 10px 0px 20px !important;
  border-width: 1px 0px 0px !important;
  border-right-style: initial;
  border-bottom-style: initial;
  border-left-style: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-image: initial;
  border-top-style: solid !important ;
  border-top-color: #cccccc !important;
}

.privacy__link {
  color: #0072ac;
  -webkit-tap-highlight-color: transparent;
}