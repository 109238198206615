.datepicker__container {
  display: flex;
  flex-direction: column;
  padding: 0 0 1.5em 0;
  width: 100%;
}

.datepicker {
  font-family: 'myriad-pro', Helvetica, Arial, sans-serif;
  padding: 0 12px;
  background-color: transparent;
  border: 1px solid #cdcdcd;
  color: #494949;
  font-size: 16px;
  height: 44px;
  border-radius: 0.25em;
  outline: none;
  transition: border-color 0.2s ease 0s, border-radius 0.2s ease 0s;
  margin-top: 0.5em;
  width: 100%;
}

.datepicker:hover,
.datepicker:focus {
  border: 1px solid #0093d8;
  box-shadow: none;
}

.datepicker--error {
  border-color: #c91b01;
}

.label {
  font-size: 16px;
  font-weight: 400;
  color: #494949;
  letter-spacing: normal;
  transition: font-size 0.2s ease 0s, transform 0.2s ease 0s, color 0.2s ease 0s;
}

.label--error {
  color: #c91b01;
}

.error__text {
  padding: 0.608em 0;
  font-size: 0.875em;
  color: #c91b01;
}
