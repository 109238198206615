* {
  box-sizing: border-box;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center; /* Added for better vertical alignment */
  height: 100vh; /* Added to ensure full viewport height */
}

.container {
  display: flex;
  flex-direction: column;
  margin: 32px 24px 0 24px;
  border-radius: 4px;
  font-weight: 600;
  line-height: 24px;
  width: 90%;
  max-height: calc(100vh - 100px);
  min-height: 480px;
  max-width: 350px;
  transition: max-width 0.2s ease;

  /* Ensure the container is centered on smaller screens */
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 600px; /* Adjusted to a more appropriate width for tablets */
  }
}

@media screen and (min-width: 960px) {
  .main {
    height: calc(100vh - 40px);
  }
  .container {
    max-width: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 800px;
  }
}

.header {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #494949;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-left: 18px;
  height: 43px;
  color: #004165;
}

.content {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  overflow-y: scroll;
  color: #747678;
  border: 1px solid #494949;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  white-space: pre-wrap;
}
.content__header{
  font-weight: bold;
  text-align: center;
}
.content__body{
  white-space: pre-wrap;
}
.sub__header{
  font-weight: bold;
}
.content__child{
  padding-left: 0.7em;
  white-space: pre-wrap;
  font-weight: 600;
}
.content__super__child{
  margin-top: -1.3em;
  padding-left: 0.7em;
  white-space: pre-wrap;
  font-weight: 600;
}
.web__link{
  color: #0072ac;
  text-decoration:underline;
  
}
.checkbox__container {
  display: flex;
  justify-content: flex-end;
}
