.cxsection__container {
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  gap: 30px;
}

@media screen and (min-width: 768px) {
  .cxsection__container {
    width: 226px;
    margin-right: 64px;
    gap: 0px;
    margin-top: 54px;
  }
}

/* The CX section is made out of two containers: cxsummary (image and text)
   and cxresources (links to other pages of ANZ)
*/
.cxsummary__container,
.cxresources__container {
  max-width: 100%;
  transition: max-width 0.2s ease 0s;
}

/* When the size of the screen reaches 480px,
   split the cx sections into two columns
   (taking up 50% of the space minus the gap of 30px)
   This is for better CX experience in landscape orientation
*/
@media screen and (min-width: 480px) {
  .cxsummary__container,
  .cxresources__container {
    flex-basis: calc(50% - 15px);
    max-width: 50%;
  }
  .cxsummary__container {
    margin-bottom: -54px;
  }
}

/* When the size of the screen reaches 768px,
   change back to taking up 100% of the allowed width
   (cxsummary and cxresources will be stacked on top of one another)
*/
@media screen and (min-width: 768px) {
  .cxsummary__container,
  .cxresources__container {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.illustration {
  width: 100%;
}

.cxsummary__text__container {
  margin-bottom: 32px;
  line-height: 1.5;
}

.cxsummary__header {
  font-weight: 600;
  font-size: 20px;
  color: #004165;
  margin-bottom: 10px;
}

.cxsummary__description {
  color: #494949;
}

.cxresources__header {
  width: 100%;
  color: #004165;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 16px;
}

.cxresources__link__container {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
}

.cxresources__link {
  text-decoration: none;
  color: #0072ac;
  -webkit-tap-highlight-color: transparent;
  box-sizing: content-box;
  height: 0px;
  overflow: visible;
  margin-bottom: 32px;
}
